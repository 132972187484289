<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <div class="row mb-4">
               <div class="col md-4">
                  <PageTitleComponent title="Detalhes da Fatura" />
               </div>
               <div v-if="invoice.status === 1" class="col-md-8 mt-3 text-end">
                  <button
                     data-bs-toggle="modal"
                     data-bs-target="#staticBackdrop"
                     class="btn btn-sm btn-primary rounded-3 px-3 me-3">
                     Alterar Situação
                  </button>
               </div>
            </div>

            <div v-if="invoice.status === 3">
               <div class="alert alert-danger" role="alert">
                  Contrato cancelado: {{ invoice.reason_status }}
               </div>
            </div>

            <div class="row">
               <div class="col-md-6">
                  <div class="row">
                     <div class="col-12 mb-4">
                        <div class="card bg-dark p-3">
                           <h5>Dados do Pagador</h5>
                           <div class="mb-3">
                              <div>Contratante: {{ invoice.library?.name }}</div>
                           </div>

                           <div class="mb-3">
                              Documento: {{ invoice.library?.document ?? 'Sem documento' }}
                           </div>

                           <div class="mb-3">
                              E-mail: {{ invoice.user?.email }}
                           </div>

                           <div class="mb-3">
                              Telefone: {{ invoice.user?.phone }}
                           </div>

                        </div>
                     </div>

                     <div class="col-12 mb-5">
                        <div class="card bg-dark p-3">
                           <h5 class="mb-3">Limites contratados</h5>
                           <div class="mb-3">
                              <div>Títulos: {{ invoice.contract?.books_limit }}</div>
                           </div>
                           <div class="mb-3">
                              <div>Leitores: {{ invoice.contract?.readers_limit }}</div>
                           </div>
                           <div class="mb-3">
                              <div>Empréstimos: {{ invoice.contract?.loans_limit ?? 'Sem limite' }}</div>
                           </div>
                           <div class="mb-3">
                              <div>Colaboradores: {{ invoice.contract?.collaborators_limit }}</div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <!-- Detalhes -->
               <div class="col-md-6 mb-5">
                  <div class="card bg-dark p-3">
                     <h5 class="mb-3">Detalhes</h5>
                     
                     <div class="mb-3">
                        <div>ID da Fatura: {{ invoice.id }}</div>
                     </div>

                     <div class="mb-3">
                        <div>ID Externo: {{ invoice.external_id }}</div>
                     </div>

                     <div class="mb-3">
                        <div>Plano: {{ invoice.contract?.plan?.name }}</div>
                     </div>

                     <div class="mb-3">
                        Valor original: R$ {{ (invoice.price) }}
                     </div>

                     <div class="mb-3">
                        Descontos: R$ {{ (invoice.discount) }}
                     </div>

                     <div class="mb-3">
                        Valor final: R$ {{ (invoice.final_price) }}
                     </div>

                     <div class="mb-3">
                        Início vigência: {{ $dayjs(invoice.start_at).format("DD/MM/YYYY") }}
                     </div>

                     <div class="mb-3">
                        Fim vigência: {{ (invoice.end_at) ? $dayjs(invoice.end_at).format("DD/MM/YYYY") : 'Indefinido' }}
                     </div>

                     <div class="mb-3">
                        <span class="me-2">Status:</span>
                        <span v-if="invoice.status == 1" class="badge bg-warning text-dark px-3">Pendente</span>
                        <span v-else-if="invoice.status == 2" class="badge bg-success px-3">Pago</span>
                        <span v-else-if="invoice.status == 3" class="badge bg-danger px-3">Cancelado</span>
                     </div>

                     <div class="mb-3">
                        Criado em: {{ $dayjs(invoice.created_at).format("DD/MM/YYYY HH:MM") }}
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </div>

      <!-- Modal add link de pagamento -->
      <div class="modal fade" id="modalChargeId" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalChargeIdLabel" aria-hidden="true">
         <div class="modal-dialog">
            <div class="modal-content">
               <div class="modal-header">
               <h5 class="modal-title" id="modalChargeIdLabel">Vincular a Clientbase</h5>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div>
                     <label>Link da Fatura</label>
                     <textarea v-model="charge_id" type="text" class="form-control"></textarea>
                  </div>
               </div>
               <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               <button @click="setPaymentLink" type="button" class="btn btn-danger">Confirmar</button>
               </div>
            </div>
         </div>
      </div>

      <!-- Modal alterar situação -->
      <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
               <div class="modal-header">
               <h5 class="modal-title" id="staticBackdropLabel">Alterar situação da cobrança</h5>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="mb-3">
                     <label>Situação</label>
                     <select v-model="form.status" class="form-select">
                        <option v-for="status in paymentStatus"
                           :key="status.code"
                           :value="status.code">
                           {{ status.name }}
                        </option>
                     </select>
                  </div>
                  <div v-if="form.status == 2" class="mb-3">
                     <label>Data de Pagamento</label>
                     <input v-model="form.date_paid" type="date" class="form-control">
                  </div>
                  <div v-if="form.status == 3" class="mb-3">
                     <label>Motivo do cancelamento</label>
                     <textarea v-model="form.annotation" type="text" class="form-control"></textarea>
                  </div>
               </div>
               <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               <button @click="setPaymentStatus" type="button" class="btn btn-primary">Confirmar</button>
               </div>
            </div>
         </div>
      </div>

      <!-- Modal pago -->
      <div class="modal fade" id="contractPaid" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="contractPaidLabel" aria-hidden="true">
         <div class="modal-dialog">
            <div class="modal-content">
               <div class="modal-header">
               <h5 class="modal-title" id="contractPaidLabel">Confirmar pagamento</h5>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div>
                     <label>Confirma o pagamento desta fatura?</label>
                  </div>
               </div>
               <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               <button @click="paidContract" type="button" class="btn btn-primary">Confirmar</button>
               </div>
            </div>
         </div>
      </div>

   </div>
</template>

<script>
import PageTitleComponent from '@/components/PageTitleComponent.vue';
import NavbarComponent from '@/components/NavbarComponent'
import ConstantsApp from '../../../constantsApp';

export default {
   name: "ContractsShowPage",
   components: {
    PageTitleComponent,
    NavbarComponent,
},

   data() {
      return {
         invoice: {},
         form: {
            status: null,
            annotation: null,
            date_paid: null,
         }
      }
   },

   mounted() {
      this.fetchInvoice();
   },

   computed: {
      paymentStatus() {
        return ConstantsApp.PaymentStatus;
      }
   },

   methods: {
      async fetchInvoice() {
         let loader = this.$loading.show();
         try {
            const response = await this.$axios.get(`master/invoices/${this.$route.params.id}`);
            this.invoice = response.data;
            this.form.status = response.data.status;
         } catch (error) {
            this.$notify({title: error.response.data.message ?? 'Contrato não localizado', type: 'error'});
            console.log(error);
         }
         loader.hide();
      },
      
      async setPaymentStatus() {
         if (this.form.status == this.invoice.status) {
            return;
         }
         
         let loader = this.$loading.show();
         
         try {
            await this.$axios.put(`master/invoices/${this.$route.params.id}/payment-status`, this.form);
            this.$notify({title: 'Cancelado com sucesso', type: 'success'});
            this.fetchInvoice();
         } catch (error) {
            console.log(error);
            this.$notify({title: error.response.data?.message ?? 'Erro', type: 'error'});
         }
         
         loader.hide();
      },

      async setPaymentLink() {
         let loader = this.$loading.show();

         try {
            const response = await this.$axios.put(`master/invoices/${this.$route.params.id}/payment-link`, {payment_link: this.charge_id});
            this.$notify({
               title: response.message, 
               type: 'success'
            });
            
            this.findContract();

         } catch (error) {
            console.log(error);
            this.$notify({title: error.response.data.message ?? 'Erro', type: 'error'});
         }

         loader.hide();
      },
   },
}
</script>
