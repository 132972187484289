<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <div class="row mb-4">
               <div class="col md-4">
                  <PageTitleComponent title="Detalhes do Contrato" />
               </div>
               <div class="col-md-8 mt-3 text-end">
                  <button
                     data-bs-toggle="modal"
                     data-bs-target="#staticBackdrop"
                     class="btn btn-sm btn-danger rounded-3 px-3 me-3">
                     Cancelar contrato
                  </button>
                  <button
                     v-if="contract.status === 1"
                     data-bs-toggle="modal"
                     data-bs-target="#paymentConfirm"
                     class="btn btn-sm btn-primary rounded-3 px-3 me-3">
                     Confirmar adesão
                  </button>
                  <button
                     v-if="contract.status === 2"
                     data-bs-toggle="modal"
                     data-bs-target="#modalInvoices"
                     class="btn btn-sm btn-primary rounded-3 px-3">
                     Criar faturas do ano
                  </button>
               </div>
            </div>

            <div v-if="contract.status === 3">
               <div class="alert alert-danger" role="alert">
                  Contrato cancelado: {{ contract.reason_status }}
               </div>
            </div>

            <div class="row">
               <div class="col-md-6">
                  <div class="row">
                     <div class="col-12 mb-4">
                        <div class="card bg-dark p-3">
                           <h5>Dados Fiscais</h5>
                           <div class="mb-3">
                              <div>Contratante: {{ contract.library.company_name }}</div>
                           </div>

                           <div class="mb-3">
                              Documento: {{ contract.library.document ?? 'Sem documento' }}
                           </div>

                           <div class="mb-3">
                              Responsável: {{ contract.user.name }}
                           </div>

                           <div class="mb-3">
                              Telefone: {{ contract.user.phone ?? 'Não informado' }}
                           </div>

                           <div class="mb-3">
                              <div>Base de Clientes ID: {{ contract.library.customer_uuid ?? 'Não informado' }}</div>
                           </div>

                        </div>
                     </div>

                     <div class="col-12 mb-5">
                        <div class="card bg-dark p-3">
                           <h5 class="mb-3">Limites contratados</h5>
                           <div class="mb-3">
                              <div>Títulos: {{ contract.books_limit }}</div>
                           </div>
                           <div class="mb-3">
                              <div>Leitores: {{ contract.readers_limit }}</div>
                           </div>
                           <div class="mb-3">
                              <div>Empréstimos: {{ contract.loans_limit ?? 'Sem limite' }}</div>
                           </div>
                           <div class="mb-3">
                              <div>Colaboradores: {{ contract.collaborators_limit }}</div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <!-- Detalhes -->
               <div class="col-md-6 mb-5">
                  <div class="card bg-dark p-3">
                     <h5 class="mb-3">Detalhes</h5>
                     
                     <div class="mb-3">
                        <div>ID do Contrato: {{ contract.id }}</div>
                     </div>

                     <div class="mb-3">
                        <div>Plano: {{ contract.plan.name }}</div>
                     </div>

                     <div class="mb-3">
                        Valor original: R$ {{ (contract.price) }}
                     </div>

                     <div class="mb-3">
                        Descontos: R$ {{ (contract.discount) }}
                     </div>

                     <div class="mb-3">
                        Valor final: R$ {{ (contract.final_price) }}
                     </div>

                     <div class="mb-3">
                        Início vigência: {{ $dayjs(contract.start_at).format("DD/MM/YYYY") }}
                     </div>

                     <div class="mb-3">
                        Fim vigência: {{ (contract.end_at) ? $dayjs(contract.end_at).format("DD/MM/YYYY") : 'Indefinido' }}
                     </div>

                     <div class="mb-3">
                        <span class="me-2">Status:</span>
                        <span v-if="contract.status == 1" class="badge bg-warning text-dark px-3">Pendente</span>
                        <span v-else-if="contract.status == 2" class="badge bg-success px-3">Ativo</span>
                        <span v-else-if="contract.status == 3" class="badge bg-danger px-3">Cancelado</span>
                     </div>

                     <div class="mb-3">
                        Criado em: {{ $dayjs(contract.created_at).format("DD/MM/YYYY HH:MM") }}
                     </div>
                  </div>
               </div>
            </div>

            <div class="col-md-12 mb-5">
               <div class="card bg-dark p-3">
                  
                  <div class="d-flex justify-content-between">
                     <h5 class="mb-3">Faturas</h5>
                     <div class="mb-3">
                        <select v-model="formInvoices.year" 
                           @change="fetchInvoices"
                           id="invoice-year" 
                           class="form-select">
                           <option v-for="i in years" :key="i" :value="i">
                              {{ i }}
                           </option>
                        </select>
                     </div>
                  </div>
                  <div class="table-responsive mb-3">
                     <table class="table table-dark table-hover">
                        <thead>
                           <tr>
                              <th style="max-width: 20px;">#</th>
                              <th>Período</th>
                              <th>Preço</th>
                              <th>Preço Final</th>
                              <th>Parceiro</th>
                              <th>Status</th>
                              <th>Pagamento</th>
                              <th></th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr v-for="invoice in invoices" :key="invoice.id">
                              <td>{{ invoice.id }}</td>
                              <td>{{ invoice.month }} / {{ invoice.year }}</td>
                              <td>R$ {{ invoice.amount.toFixed(2) }}</td>
                              <td>R$ {{ invoice.total.toFixed(2) }}</td>
                              <td>{{ invoice.partner_id ? invoice?.partner.name : 'Nenhum' }}</td>
                              <td>
                                 <span v-if="invoice.status == 1" class="badge bg-warning text-dark">Pendente</span>
                                 <span v-else-if="invoice.status == 2" class="badge bg-success">Pago</span>
                                 <span v-else-if="invoice.status == 3" class="badge bg-danger">Cancelado</span> 
                              </td>
                              <td>
                                 <span v-if="invoice.due_date">{{ $dayjs(invoice.due_date).format("DD/MM/YYYY") }}</span>
                                 <span v-else>Nenhum</span>
                              </td>
                              <td>
                                 <router-link 
                                    :to="`/invoices/${invoice.id}/show`" 
                                    class="badge bg-dark px-3" 
                                    style="cursor: pointer;">
                                    Detalhes</router-link>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- Modal add link de pagamento -->
      <div class="modal fade" id="modalChargeId" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalChargeIdLabel" aria-hidden="true">
         <div class="modal-dialog">
            <div class="modal-content">
               <div class="modal-header">
               <h5 class="modal-title" id="modalChargeIdLabel">Vincular a Clientbase</h5>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div>
                     <label>Link da Fatura</label>
                     <textarea v-model="charge_id" type="text" class="form-control"></textarea>
                  </div>
               </div>
               <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               <button @click="setPaymentLink" type="button" class="btn btn-danger">Confirmar</button>
               </div>
            </div>
         </div>
      </div>

      <!-- Modal confirmar adesão -->
      <div class="modal fade" id="paymentConfirm" data-bs-keyboard="false" tabindex="-1" aria-labelledby="paymentConfirmLabel" aria-hidden="true">
         <div class="modal-dialog">
            <div class="modal-content">
               <div class="modal-header">
               <h5 class="modal-title" id="paymentConfirmLabel">Confirmar Adesão</h5>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <p>Deseha confirmar a adesão do cliente?</p>
               </div>
               <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               <button @click="setConfirmation" type="button" class="btn btn-primary">Confirmar</button>
               </div>
            </div>
         </div>
      </div>

      <!-- Modal cancelar -->
      <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
         <div class="modal-dialog">
            <div class="modal-content">
               <div class="modal-header">
               <h5 class="modal-title" id="staticBackdropLabel">Cancelar cobrança</h5>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div>
                     <label>Motivo do cancelamento</label>
                     <textarea v-model="form.reason" type="text" class="form-control"></textarea>
                  </div>
               </div>
               <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               <button @click="cancelContract" type="button" class="btn btn-danger">Confirmar</button>
               </div>
            </div>
         </div>
      </div>

      <!-- Modal Faturas -->
      <div class="modal fade" ref="modalInvoices" id="modalInvoices" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalInvoicesLabel" aria-hidden="true">
         <div class="modal-dialog">
            <div class="modal-content">
               <div class="modal-header">
               <h5 class="modal-title" id="modalInvoicesLabel">Cadastrar Faturas</h5>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="mb-3">
                     <label>Parceiro?</label>
                     <select v-model="formInvoices.partner_id" class="form-select">
                        <option value="">Nenhum</option>
                        <option value="1">Kinto</option>
                     </select>
                  </div>
               </div>
               <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               <button @click="createInvoices" type="button" class="btn btn-primary">Confirmar</button>
               </div>
            </div>
         </div>
      </div>

   </div>
</template>

<script>
import PageTitleComponent from '@/components/PageTitleComponent.vue';
import NavbarComponent from '@/components/NavbarComponent'
import { Modal } from 'bootstrap';

export default {
   name: "ContractsShowPage",
   components: {
    PageTitleComponent,
    NavbarComponent,
},

   data() {
      return {
         contract: {
            id: null,
            user: {},
            plan: {},
            library: {},
         },
         form: {
            reason: null,
         },
         charge_id: null,
         invoices: [],
         formInvoices: {
            contract_id: null,
            partner_id: "",
            year: new Date().getFullYear(),
         },
         years: [ 2023, 2024, 2025 ],
      }
   },

   mounted() {
      this.findContract();
      this.fetchInvoices();
      this.formInvoices.contract_id = this.$route.params.id;
   },

   methods: {
      async fetchInvoices() {
         let loader = this.$loading.show();
         
         try {
            const response = await this.$axios.get(`master/invoices?year=${this.formInvoices.year}&contract_id=${this.$route.params.id}`);
            this.invoices = response.data;
         } catch (error) {
            console.log(error);
         }
         loader.hide();
      },
      async createInvoices() {
         let loader = this.$loading.show();

         try {
            const response = await this.$axios.post(`master/invoices`, this.formInvoices);
            this.$notify({title: response.data.message ?? 'Contrato criado', type: 'success'});
         } catch (error) {
            this.$notify({title: error.response.data.message ?? 'Contrato não localizado', type: 'error'});
         }

         const modalInstance = Modal.getInstance(this.$refs.modalInvoices) || new Modal(this.$refs.modalInvoices);
         modalInstance.hide();
         
         loader.hide();
      },
      async findContract() {
         let loader = this.$loading.show();

         try {
            const response = await this.$axios.get(`master/contracts/${this.$route.params.id}`);
            this.contract = response.data;
         } catch (error) {
            console.log(error);
            this.$notify({title: error.response.data.message ?? 'Contrato não localizado', type: 'error'});
         }

         loader.hide();
      },

      async cancelContract() {
         let loader = this.$loading.show();
         try {
            await this.$axios.put(`master/contracts/${this.contract.id}/cancel`, this.form);
            this.$notify({title: 'Cancelado com sucesso', type: 'success'});
            this.findContract();
         } catch (error) {
            console.log(error);
            this.$notify({title: error.response.data.message ?? 'Erro', type: 'error'});
         }
         loader.hide();
      },

      async setConfirmation() {

         let loader = this.$loading.show();
         try {
            await this.$axios.put(`master/contracts/${this.contract.id}/paid`, {});
            this.$notify({title: 'Pago com sucesso', type: 'success'});
            window.location.reload();
         } catch (error) {
            console.log(error);
            this.$notify({title: error.response.data.message ?? 'Erro', type: 'error'});
         }
         loader.hide();
      },

      async setPaymentLink() {
         let loader = this.$loading.show();
         try {
            const response = await this.$axios.put(`master/contracts/${this.$route.params.id}/payment-link`, {payment_link: this.charge_id});
            this.$notify({
               title: response.message, 
               type: 'success'
            });
            this.findContract();

         } catch (error) {
            console.log(error);
            this.$notify({title: error.response.data.message ?? 'Erro', type: 'error'});
         }
         loader.hide();
      },

      paymentMethod(id) {
         switch (id) {
            case '1': return 'Pix';
            case '2': return 'Cartão';
            case '3': return 'Boleto';
            case '4': return 'Voucher';
         }
      }
   },
}
</script>
