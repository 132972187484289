const ConstantsApp = {
  Dewey: [
    {id: 1, code: '000', name: '000 Generalidades'},
    {id: 2, code: '100', name: '100 Filosofia'},
    {id: 3, code: '200', name: '200 Religião'},
    {id: 4, code: '300', name: '300 Ciências sociais'},
    {id: 5, code: '400', name: '400 Línguas'},
    {id: 6, code: '500', name: '500 Ciências puras'},
    {id: 7, code: '600', name: '600 Ciências aplicadas'},
    {id: 8, code: '700', name: '700 Artes'},
    {id: 9, code: '800', name: '800 Literatura'},
    {id: 10, code: '900', name: '900 História e geografia'}
  ],

  Estados: [
    {uf: "AC", id: 1, name: "Acre"},
    {uf: "AL", id: 2, name: "Alagoas"},
    {uf: "AP", id: 3, name: "Amapá"},
    {uf: "AM", id: 4, name: "Amazonas"},
    {uf: "BA", id: 5, name: "Bahia"},
    {uf: "CE", id: 6, name: "Ceará"},
    {uf: "DF", id: 7, name: "Distrito Federal"},
    {uf: "ES", id: 8, name: "Espírito Santo"},
    {uf: "GO", id: 9, name: "Goiás"},
    {uf: "MA", id: 10, name: "Maranhão"},
    {uf: "MT", id: 11, name: "Mato Grosso"},
    {uf: "MS", id: 12, name: "Mato Grosso do Sul"},
    {uf: "MG", id: 13, name: "Minas Gerais"},
    {uf: "PA", id: 14, name: "Pará"},
    {uf: "PB", id: 15, name: "Paraíba"},
    {uf: "PR", id: 16, name: "Paraná"},
    {uf: "PE", id: 17, name: "Pernambuco"},
    {uf: "PI", id: 18, name: "Piauí"},
    {uf: "RJ", id: 19, name: "Rio de Janeiro"},
    {uf: "RN", id: 20, name: "Rio Grande do Norte"},
    {uf: "RS", id: 21, name: "Rio Grande do Sul"},
    {uf: "RO", id: 22, name: "Rondônia"},
    {uf: "RR", id: 23, name: "Roraima"},
    {uf: "SC", id: 24, name: "Santa Catarina"},
    {uf: "SP", id: 25, name: "São Paulo"},
    {uf: "SE", id: 26, name: "Sergipe"},
    {uf: "TO", id: 27, name: "Tocantins"}
  ],

  PaymentMethods: [
    {code: 1, name: 'Pix'}, 
    {code: 2, name: 'Cartão'}, 
    {code: 3, name: 'Boleto'}, 
    {code: 4, name: 'Voucher'}
  ],

  PaymentStatus: [
    {code: 1, name: 'Pendente'}, 
    {code: 2, name: 'Pago'}, 
    {code: 3, name: 'Cancelado'}
  ]
}

export default ConstantsApp